var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iot-login-bg"},[_c('a-row',{staticClass:"iot-login",attrs:{"type":"flex","justify":"space-around","align":"middle"}},[_c('a-col',{staticClass:"iot-login-left",attrs:{"span":14}},[_c('img',{attrs:{"src":require("@/assets/images/login.jpg")}})]),_c('a-col',{staticClass:"iot-login-center",attrs:{"span":6}},[_c('div',{staticClass:"iot-login-form",staticStyle:{"background-color":"#fff","padding":"20px","box-sizing":"border-box","border-radius":"10px"}},[_c('h1',{staticClass:"iot-login-title",staticStyle:{"text-align":"center"}},[_vm._v(" 物联网连接管理平台 ")]),_c('div',{staticClass:"login-border"},[_c('div',{staticClass:"login-content"},[_c('br'),_c('a-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isQrcLogin),expression:"!isQrcLogin"}],attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username', {
                    normalize: this.$lodash.trim,
                    rules: [
                      { required: true, message: '请输入登录账号' }
                    ]
                  }]),expression:"['username', {\n                    normalize: this.$lodash.trim,\n                    rules: [\n                      { required: true, message: '请输入登录账号' }\n                    ]\n                  }]"}],attrs:{"placeholder":"账号"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
                    rules: [
                      { required: true, message: '请输入登录密码' }
                    ]
                  }]),expression:"['password', {\n                    rules: [\n                      { required: true, message: '请输入登录密码' }\n                    ]\n                  }]"}],attrs:{"type":"password","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":18}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['captcha', {
                        normalize: this.$lodash.trim,
                        rules: [{ required: true, message: '请输入验证码' }]
                      }]),expression:"['captcha', {\n                        normalize: this.$lodash.trim,\n                        rules: [{ required: true, message: '请输入验证码' }]\n                      }]"}],attrs:{"placeholder":"验证码"}})],1),_c('a-col',{attrs:{"span":6}},[_c('a-tooltip',[_c('template',{slot:"title"},[_vm._v(" 点击刷新验证码 ")]),_c('a-spin',{attrs:{"spinning":_vm.loadingCaptchaImg}},[(_vm.captchaId.length > 0)?_c('img',{staticClass:"captcha-img iotplt-cursor-pointer",attrs:{"src":_vm.captchaImgUrl},on:{"click":_vm.reloadCaptcha,"load":_vm.captchaImgLoaded}}):_vm._e()])],2)],1)],1)],1),_c('a-form-item',{staticStyle:{"display":"flex","align-content":"center","justify-content":"center"}},[_c('a-button',{staticClass:"iot-login-form-button",staticStyle:{"width":"200px"},attrs:{"loading":_vm.submitting,"type":"primary","html-type":"submit","size":"large"}},[_vm._v(" 登录 ")])],1),_c('a',{on:{"click":_vm.changeQrcLogin}},[_vm._v("扫码登录")])],1),_c('a-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.isQrcLogin),expression:"isQrcLogin"}]},[_c('a-form-item',{staticStyle:{"text-align":"center"}},[_c('iframe',{attrs:{"src":_vm.qrcUrl,"scrolling":"no","width":"300px","height":"330px","frameborder":"0"}})]),_c('a',{on:{"click":_vm.changeQrcLogin}},[_vm._v("账号登录")])],1)],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }